import React, { useState } from "react";
import {
  Input,
  Dropdown,
  DropdownContent,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem
} from "bloomer";
import styled from "@emotion/styled";


const InputDropdownMenu = styled(DropdownMenu)`
  padding-top: 0px;
  width: 100%;
  padding-left: 1px;
  padding-right: 1px;
`;

export const MapSearch = ({
  organizations = [],
  onOrganizationClicked = () => {}
}) => {
  const [searchText, setSearchText] = useState("");

  // if (loading) return (<div class="loader-wrapper"> <div class="loader is-loading"></div> </div>)
  // if (error) return <p>Error! ${error.message}</p>

  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Destructuring_assignment#Object_destructuring

  const inputAutocompleteMenuItems = organizations
    .filter(({ name }) => name.toLowerCase().includes(searchText.toLowerCase()))
    .map(organization => (
      <DropdownItem
        key={organization.id}
        href="#"
        onClick={event => {
          event.preventDefault();
          setSearchText("");
          onOrganizationClicked(organization);
        }}
      >
        {organization.name}
      </DropdownItem>
    ));

  return (
    <Dropdown isActive={searchText !== ""}>
      <DropdownTrigger>
        <Input
          value={searchText}
          onChange={event => setSearchText(event.target.value)}
          placeholder="Search"
          hasTextAlign="centered"
        />
      </DropdownTrigger>
      <InputDropdownMenu>
        <DropdownContent>{inputAutocompleteMenuItems}</DropdownContent>
      </InputDropdownMenu>
    </Dropdown>
  );
};
