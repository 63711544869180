import React, { useState } from "react";
import { Form, Row, Col, Button, Alert } from "react-bootstrap";
import { gql } from "apollo-boost";
import { submitOrganizationApplication } from "../queries";
import { useMutation } from "react-apollo";

export const Enrollment = () => {
    const [organizationName, setOrganizationName] = useState("")
    const [organizationDescription, setOrganizationDescription] = useState("")
    const [organizationType, setOrganizationType] = useState("")
    const [organizationPage, setOrganizationPage] = useState("")
    const [organizationEmail, setOrganizationEmail] = useState("")
    const [organizationPhone, setOrganizationPhone] = useState("")
    const [userSelectedOther, setUserSelectedOther] = useState(false)

    const [submitFailure, setSubmitFailure] = useState(null)
    const [alertShow, setAlertShow] = useState(false);

    const [submitApplication, {loading: mutationLoading, error: mutationError}] = useMutation(gql(submitOrganizationApplication))

    const submitApplicationHandler = () => {
        submitApplication({ variables: {description: organizationDescription, email: organizationEmail, name: organizationName, phone: organizationPhone, website: organizationPage, type: organizationType}})
        .then(() => {
            setSubmitFailure(mutationError !== undefined)
            setAlertShow(true)
        })
    }
    
    return(
        <>
        <div style={{marginTop:"1em"}}>
            <h1 className="text-center">Submit an Application</h1>
        </div>
        {(submitFailure && alertShow) && (
            <Alert variant="danger" onClose={() => setAlertShow(false)} dismissible style={{position: "absolute", right:"0", bottom:"0", zIndex:"9"}}>
                <Alert.Heading>Oh snap! Failed to submit application</Alert.Heading>
                <hr/>
                <div>
                    We were not able to submit your application, please try again later!
                </div>
            </Alert>
        )}

        {(!submitFailure && alertShow) && (
            <Alert variant="success" onClose={() => setAlertShow(false)} dismissible style={{position: "absolute", right:"0", bottom:"0", zIndex:"9"}}>
                <Alert.Heading>Sweet! Your application was submitted succesfully</Alert.Heading>
                <div>
                    We got your application! You should here from us directly within 24-48 hours.<br/>Thank you for wanting to join Knowhere!
                </div>
            </Alert>
        )}
            <div style={{margin: "1em"}}>

                <Form style={{marginTop: "1em"}}>
                <Row className = "mb-3">
                    <Form.Group as={Col}>
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" placeholder="Enter name" value={organizationName} onChange={e => setOrganizationName(e.target.value)}/>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>Organization Type</Form.Label>
                        <Form.Control as="select" defaultValue="Choose..." onChange={(e) => {setOrganizationType(e.target.value)
                            if(e.target.value === "Other") {
                                setUserSelectedOther(true)
                            } else {
                                setUserSelectedOther(false)
                            }
                        }}>
                            <option>Choose...</option>
                            <option>Greek</option>
                            <option>Food & Drink</option>
                            <option>Music</option>
                            <option>Other</option>
                        </Form.Control>
                    </Form.Group>
                </Row>

                {userSelectedOther && (
                    <Form.Group>
                        <Form.Label>Other Type</Form.Label>
                        <Form.Control placeholder="Enter type" onChange={e => setOrganizationType(e.target.value)}/>
                    </Form.Group>
                )}

                <Form.Group>
                    <Form.Label>Description</Form.Label>
                    <Form.Control as="textarea" placeholder="Describe your organization" style={{ height: '100px' }} value={organizationDescription} onChange={e => setOrganizationDescription(e.target.value)}/>
                </Form.Group>
                </Form>

                <Row className = "mb-3">
                    <Form.Group as={Col}>
                        <Form.Label>Phone</Form.Label>
                        <Form.Control placeholder="Enter phone number" value={organizationPhone} onChange={e => setOrganizationPhone(e.target.value)}/>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" placeholder="Enter email" value={organizationEmail} onChange={e => setOrganizationEmail(e.target.value)}/>
                    </Form.Group>

                </Row>

                <Form.Group>
                    <Form.Label>Website Url</Form.Label>
                    <Form.Control placeholder="Enter website url" value={organizationPage} onChange={e => setOrganizationPage(e.target.value)}/>
                </Form.Group>
                <Button size ="lg" class="text-center" onClick={!mutationLoading ? submitApplicationHandler : null}>{!mutationLoading ? "Submit" : "Loading..."}</Button>
                <div style={{marginTop: "2em", textAlign: "center"}} class="text-muted">Knowhere is currently only allowing organizations to join the network through an application process.
                    Please give us 24-48 hours to respond to your application.
                </div>
            </div>
        </>
    );
}