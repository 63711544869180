import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Button as navButton,
  Panel,
  PanelBlock,
  PanelHeading,
  Input,
  Control,
  Container,
  Column
} from "bloomer";
import { Button , Spinner} from "react-bootstrap"
import { BlackIcon } from "../helpers/black_icon";
import { PanelTabs } from "bloomer/lib/components/Panel/PanelTabs";
import { PanelTab } from "bloomer/lib/components/Panel/PanelTab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faMapMarkedAlt
} from "@fortawesome/free-solid-svg-icons";
import styled from "@emotion/styled-base";
import { getAllEvents } from "../queries.js";
import Collapsible from "react-collapsible";
import { useQuery } from "@apollo/react-hooks";
import '../helpers/_Collapsible.scss';



const OrganizationContainer = styled(Container)`
  font-size: 0.9em;
  overflow: auto;
  width: 100%;
} 
`;

const EventPanel = styled(Panel)`
  .list{
    margin-bottom: 5em;
  }
`;

const MapButton = styled(navButton)`
  position: fixed;
  bottom: 1em;
  left: 0.5em;
  height: 50px;
  width: 50px;
  z-index: 2;
  border-radius: 50%;
`;

const ClosedOrganization = styled(Container)`
  margin-right: .3em;
`;

const OpenedOrganization = styled(Container)`
  font-size: 0.9em;
  overflow: auto;
  .event-title {
    font-size: 1.5em;
    color: white;
    font-weight: bold;
    text-align: center;
  }
`;

const EventCollapsible = styled(Collapsible)`
  font-size: 18px;
  margin: .2em;
  .button{
    margin-top: 25px;
  }
`;

const ProfileButton = styled(Button)`
  margin-top: 25px;
`;


export const Events = () => {
  const [selectedPanel, setSelectedPanel] = useState("all");
  const [searchText, setSearchText] = useState("");
  var [today] = useState(() => new Date(Date.now()))
  const { loading, error, data } = useQuery(getAllEvents, {variables: {time: today}});

  if (loading)
    return (
    <div>
      <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
          <Spinner animation="border" variant="primary" style={{width: "100px", height: "100px"}}/>
      </div>
      <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>Loading...Please Wait!</div>
    </div>    
    );
  if (error) return <p>Error! ${error.message}</p>;

  function replaceURLs(message) {
    if(!message) return;

    var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    return message.replace(urlRegex, function (url) {
      var hyperlink = url;
      if (!hyperlink.match('^https?:')) {
        hyperlink = 'http://' + hyperlink;
      }
      var str = '<a href="' + hyperlink + '" target="_blank" rel="noopener noreferrer">' + url + '</a>'
      return str;
    });
  }

  const friends = data.organizations
    .filter(friendData => {
      return (
        (selectedPanel === "all" || friendData.type === selectedPanel) &&
        friendData.name.toLowerCase().includes(searchText.toLowerCase())
      );
    })
    .map(organization => {
      var startDate = new Date(organization.events[0].timestamp);
      var endDate = new Date(organization.events[0].timestamp_end);
      var startTime = startDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true})
      var endTime = endDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true})
      var stamp = startDate.getMonth() + "/" + startDate.getDate() + "/" + startDate.getFullYear() + " | " + startTime + " - " +
        endDate.getMonth() + "/" + endDate.getDate() + "/" + endDate.getFullYear() + " | " + endTime
      ;
      
      var parser = new DOMParser();
      var normalizedDescription = replaceURLs(organization.events[0].description)
      const doc = parser.parseFromString(normalizedDescription, 'text/html')

      const Trigger = () => <ClosedOrganization>{
        organization.name + ": " + organization.events[0].title +
        " -- " +
        stamp
      }</ClosedOrganization>

      const OpenCollapsible = () => <div>{
        <>
          <OpenedOrganization>
          <h1 className="event-title">{organization.events[0].title}</h1>
            <p>Organization: {organization.name}</p>
            <p>Time: {stamp}</p>
            <p>Location: {" "}{organization.events[0].address}</p>

          </OpenedOrganization>
        </>
      }</div>
      
      return (
        <OrganizationContainer key={organization.id}>
          <EventCollapsible
            trigger={<Trigger />}
            triggerWhenOpen={<OpenCollapsible />}
            transitionCloseTime="0"
          >            
            <p>Description:</p>
            <Column hasTextAlign="centered">
            <div dangerouslySetInnerHTML={{ __html: doc.body.innerHTML }}/>
                <Link
                  key={organization.id}
                  to={"/events/" + organization.name}
                >
                  <ProfileButton isColor="primary" isOutlined hasTextAlign="centered">
                    View Profile
                  </ProfileButton>
                </Link>
            </Column>
          </EventCollapsible>
        </OrganizationContainer>
      );
    });

  return (
    <>
      <EventPanel>
        <PanelHeading>Upcoming Events In Boulder</PanelHeading>
        <PanelBlock>
          <Control hasIcons="left">
            <Input
              value={searchText}
              onChange={event => setSearchText(event.target.value)}
              isSize="medium"
              placeholder="Search"
            />
            <span className="icon is-left">
              <FontAwesomeIcon icon={faSearch} size="xs" />
            </span>
          </Control>
        </PanelBlock>
        <PanelTabs>
          <PanelTab
            isActive={selectedPanel === "all"}
            onClick={() => setSelectedPanel("all")}
          >
            All
          </PanelTab>
          <PanelTab
            isActive={selectedPanel === "Greek"}
            onClick={() => setSelectedPanel("Greek")}
          >
            Greek
          </PanelTab>
          <PanelTab
            isActive={selectedPanel === "Music"}
            onClick={() => setSelectedPanel("Music")}
          >
            Music
          </PanelTab>
          <PanelTab
            isActive={selectedPanel === "Food_Drink"}
            onClick={() => setSelectedPanel("Food & Drink")}
          >
            Food & Drink
          </PanelTab>
        </PanelTabs>
        <div className="list">
          {friends}
        </div>
      </EventPanel>
        <Link to="/">
            <MapButton>
              <BlackIcon icon={faMapMarkedAlt}></BlackIcon>
            </MapButton>
        </Link>
    </>
  );
};
