import React, { useState } from "react";
import { Button, Card, Image, Spinner } from "react-bootstrap"
import { organizationEvents } from "../queries";
import { useQuery } from "react-apollo";
import { useHistory } from "react-router-dom";

export const Organization = props => {
  const organizationName = props.match.params.organizationName;
  let history = useHistory();
  var [today] = useState(() => new Date(Date.now()))

  var hasBio = false;
  var hasEvents = false;
  const { loading, error, data } = useQuery(organizationEvents, {
    variables: { organizationName: organizationName, time: today }
  });
  if (loading) {
    return(
      <div>
      <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
          <Spinner animation="border" variant="primary" style={{width: "100px", height: "100px"}}/>
      </div>
      <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>Getting all events for {organizationName}</div>
      </div>    
    );
  }
  if (error) return <p>Error! ${error.message}</p>;

  function replaceURLs(message) {
    if(!message) return;

    var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    return message.replace(urlRegex, function (url) {
      var hyperlink = url;
      if (!hyperlink.match('^https?:')) {
        hyperlink = 'http://' + hyperlink;
      }
      var str = '<a href="' + hyperlink + '" target="_blank" rel="noopener noreferrer">' + url + '</a>'
      return str;
    });
  }

  if(data.organizations[0].bio !== null)
    hasBio = true

  if (data.organizations[0].events.length > 0) {
    hasEvents = true
  }
  const events = data.organizations[0].events
  .map(event => {
    var parser = new DOMParser();
    var normalizedDescription = replaceURLs(event.description)
    const doc = parser.parseFromString(normalizedDescription, 'text/html')

    var startDate = new Date(event.timestamp);
    var endDate = new Date(event.timestamp_end)

    var startTime = startDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true})
    var endTime = endDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true})

    return (
        <Card style={{margin: '1em'}}>
            <Card.Header as="h4">{event.title}</Card.Header>
            <Card.Body>
                <Card.Title>Location: {event.address}</Card.Title>
                <Card.Title>Time: {startDate.getMonth() + "/" + startDate.getDate() + "/" + startDate.getFullYear() + " @ " + startTime + " - " +
                  endDate.getMonth() + "/" + endDate.getDate() + "/" + endDate.getFullYear() + " @ " + endTime
                }
                </Card.Title>
                <div dangerouslySetInnerHTML={{ __html: doc.body.innerHTML }}/>
            </Card.Body>
        </Card>
    );
  })

  return (
    <>
        <Button isOutlined isColor="primary"
        onClick={() => history.goBack()}
        style={{margin: "1em"}}
        >
          Back
        </Button>

      <div className="text-center">
      <Image src= {data.organizations[0].profile_picture} rounded/>
        <h1>{organizationName}</h1>
        <h5 className="mb-2 text-muted">{data.organizations[0].type}</h5>
        {hasBio && (
                <h4>Bio: {data.organizations[0].bio}</h4>
              )}
        {events}
      </div>
      {!hasEvents && (
      <div className="text-center">
        <h6 className="mb-2 text-muted">{organizationName} does not have any upcoming events</h6>
      </div>
    )}
    </>
  );
};
