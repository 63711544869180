import React, { useState, useMemo } from "react";
import { Map } from "../components/map";
import { Button } from "bloomer";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { faCalendar, faCity } from "@fortawesome/free-solid-svg-icons";
import { BlackIcon } from "../helpers/black_icon";
import { MapSearch } from "../components/MapSearch";
import { useQuery } from "@apollo/react-hooks";
import { getAllEvents } from "../queries.js";
import { Spinner } from "react-bootstrap";

const InputDiv = styled.div`
  text-align: center;
  margin: 0 auto;
  max-width: 60%;
`;

const CalendarButton = styled(Button)`
  position: fixed;
  bottom: 1em;
  right: 0.5em;
  height: 50px;
  width: 50px;
  z-index: 2;
  border-radius: 50%;
`;

const OrganizationButton = styled(Button)`
  position: fixed;
  bottom: 1em;
  left: 0.5em;
  height: 50px;
  width: 50px;
  z-index: 2;
  border-radius: 50%;
`;

export const Home = () => {
  const [mapViewport, setMapViewport] = useState({
    latitude: 40.015,
    longitude: -105.2705,
    zoom: 14
  });

  const [clickedOrganizationId, setClickedOrganizationId] = useState(null);
  var [today] = useState(() => new Date(Date.now()))

  const { loading, error, data = { organizations: [] } } = useQuery(
    getAllEvents, {variables: {time: today}}
  );

  // Hack until John figures out how to more efficiently load events
  const clickedOrganizationIndex = useMemo(
    () =>
      data.organizations.findIndex(({ id }) => id === clickedOrganizationId),
    [data.organizations, clickedOrganizationId]
  );

  if (loading)
    return (
      <>
      <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
          <Spinner animation="border" variant="primary" style={{width: "100px", height: "100px"}}/>
      </div>
      <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>Loading...Please Wait!</div>
  </>    
    );
  if (error) return <p>Error! ${error.message}</p>;

  const { organizations = [] } = data;

  return (
    <>
      <Map
        viewport={mapViewport}
        onViewportChange={setMapViewport}
        clickedOrganizationIndex={clickedOrganizationIndex}
        onOrganizationPinClicked={setClickedOrganizationId}
        organizations={data.organizations}
      >
        <OrganizationButton>
          <Link to="/organizations">
            <BlackIcon icon= { faCity } />
          </Link>
        </OrganizationButton>

        <CalendarButton>
          <Link to="/events">
              <BlackIcon icon={faCalendar} />
          </Link>
        </CalendarButton>


        <InputDiv>
          <MapSearch
            organizations={organizations}
            onOrganizationClicked={({ id, events = [] }) => {
              const [{ latitude, longitude }] = events;
              setMapViewport(prevState => ({
                ...prevState,
                latitude,
                longitude,
                zoom: 18
              }));
              setClickedOrganizationId(id);
            }}
          />
        </InputDiv>
      </Map>
    </>
  );
};
