import React from "react";
import { Card, Spinner } from "react-bootstrap";
import { Button } from "bloomer";
import { getAllOrganizations } from "../queries";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { Link } from "react-router-dom";
import { faMapMarkedAlt } from "@fortawesome/free-solid-svg-icons";
import { BlackIcon } from "../helpers/black_icon";
import styled from "@emotion/styled";

export const AllOgranizations = () => {

    const MapButton = styled(Button)`
        position: fixed;
        bottom: 1em;
        right: 0.5em;
        height: 50px;
        width: 50px;
        border-radius: 50%;
    `;
    const { loading, data = { organizations: [] } } = useQuery(
        gql(getAllOrganizations));

    if (loading)
        return (
            <div>
                <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <Spinner animation="border" variant="primary" style={{width: "100px", height: "100px"}}/>
                </div>
                <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>Loading...Please Wait!</div>
            </div>    
        );

    const organizations = data.organizations
    .map(organization => {
            
        return(
            <Card className="text-center">
                <Card.Img variant="top" src= {organization.profile_picture} style={{borderBottom: ".1rem solid #ececec"}}/>
                <Card.Body>
                    <Card.Title>{organization.name}</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">{organization.type}</Card.Subtitle>
                    <Card.Text>{organization.bio}</Card.Text>
                </Card.Body>
                <Link
                    key={organization.id}
                    to={"/events/" + organization.name}
                >
                    <Button style={{margin: "0.5em"}}>View</Button>
                </Link>                
            </Card>
            
        )
    })    
    return(
        <>
            <div style={{marginTop:"1em"}}>
                <h1 className="text-center">Organizations on the Knowhere Network</h1>
                <div style={{display: "grid", gridTemplateColumns: "repeat(auto-fill, minmax(100px, 1fr))", gap:"1em", margin:"3em"}}>
                    {organizations}
                </div>
            </div>

        <MapButton>
            <Link to="/">
                    <BlackIcon icon = { faMapMarkedAlt } />
            </Link>
        </MapButton>
        <div style={{textAlign: "center", marginBottom: "2em"}}>
            <div>
            Are you an Organization wanting to join?
            </div>
            <a href="Signup" target="_blank" rel="noopener noreferrer">Click Here!</a>
          </div>
        </>
    );
}