import React from "react";
import ReactMapGL, { Popup } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import Pins from "./pins";
import OrganizationInfo from "./organization-info";

export const Map = ({
  children,
  viewport,
  onViewportChange,
  clickedOrganizationIndex,
  onOrganizationPinClicked,
  organizations = []
}) => {
  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <ReactMapGL
        mapboxApiAccessToken={
          "pk.eyJ1IjoiZmxldGNoc3R1ZCIsImEiOiJjazFscmg3cnMwNnA3M2NrdmJyZGd4OHp1In0.AHuCxCzBGQnO-H-myqUY6A"
        }
        {...viewport}
        width={"100%"}
        height={"100%"}
        onViewportChange={onViewportChange}
        mapStyle="mapbox://styles/mapbox/streets-v11"
      >
        <Pins
          data={organizations}
          onClick={onOrganizationPinClicked}
          showPopup={clickedOrganizationIndex}
        />
        {clickedOrganizationIndex !== -1 && (
          <Popup
            longitude={
              organizations[clickedOrganizationIndex].events[0].longitude
            }
            latitude={
              organizations[clickedOrganizationIndex].events[0].latitude
            }
            closeButton={true}
            closeOnClick={false}
            autoPan={false}
            anchor={"bottom"}
            offsetTop={-15}
            dynamicPosition={false}
            onClose={() => onOrganizationPinClicked(null)}
          >
            <OrganizationInfo info={organizations[clickedOrganizationIndex]} />
          </Popup>
        )}
        {children}
      </ReactMapGL>
    </div>
  );
};
