import gql from "graphql-tag";

export const getAllEvents = gql`
query getAllEvents($time: timestamptz!) {
  organizations(where: {events: {id: {_neq: null}, _and: {timestamp_end: {_gte: $time}}}}) {
    name
    profile_picture
    type
    id
    events(limit: 1, order_by: {timestamp: asc}) {
      description
      title
      timestamp
      timestamp_end
      address
      latitude
      longitude
    }
  }
}
`;

export const organizationEvents = gql`
query organizationEvents($organizationName: String!, $time: timestamptz!) {
  organizations(where: {name: {_eq: $organizationName}}) {
    type
    bio
    profile_picture
    events(order_by: {timestamp: asc}, where: {timestamp_end: {_gte: $time}}) {
      description
      title
      timestamp
      timestamp_end
      address
    }
  }
}
`;
//Query Variables for getNextOrganizationEvent Example:
/*
  {
    "organizationId": 3
  }
*/

export const addEvent = gql`
  mutation addEvent($objects: [event_insert_input!]!) {
    insert_event(objects: $objects) {
      returning {
        description
        timestamp
        address
      }
    }
  }
`;

//Query Variables for addEvent Example:
/*
{
  "objects": [
    {
      "description": "This is a TEST",
      "timestamp": "2020-02-14T03:22:15.739+00:00",
      "address": "The Barn",
      "organization_id": 1 
    }
  ]
}
*/

export const editEvent = gql`
  mutation editEvent($id: Int, $changes: event_set_input) {
    update_event(where: { id: { _eq: $id } }, _set: $changes) {
      affected_rows
      returning {
        description
        timestamp
        address
      }
    }
  }
`;

//Query Variables for editEvent Example:
/*
{
  "id": 5,
  "changes": {
    "description": "Changing description of TEST",
    "address": "NOT THE BARN"
  }
}
*/

export const deleteEvent = gql`
  mutation deleteEvent($event_id: Int!) {
    delete_event(where: { id: { _eq: $event_id } }) {
      affected_rows
    }
  }
`;

export const getAllOrganizations = `
{
  organizations(order_by: {type: asc}, where: {type: {_neq: "null"}}) {
    type
    name
    bio
    profile_picture
  }
}
`;

export const submitOrganizationApplication = `
  mutation ($description: String!, $email: String!, $name: String!, $phone: String!, $website: String!, $type: String!) {
    insert_applications(objects: {description: $description, email: $email, name: $name, type: $type, phone: $phone, website: $website}) {
      affected_rows
    }
  }
`

//Query Variables for deleteEvent Example:
/*
{
  "event_id":  5
}
*/
