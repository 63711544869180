import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import { Events } from "./pages/events";
import "bulma/css/bulma.css";
import { Home } from "./pages/home";
import { Organization } from "./pages/organzation";
import { AllOgranizations } from "./pages/organizations";
import { ApolloClient, HttpLink, InMemoryCache } from "apollo-boost";
import { ApolloProvider } from "react-apollo";
import { Enrollment } from './pages/enrollment';


const cache = new InMemoryCache();
const link = new HttpLink({
  uri: "https://connect-react-app.herokuapp.com/v1/graphql",
  headers: {
    "x-hasura-role" : "anonymous" 
  }
});

const client = new ApolloClient({
  cache,
  link,
});

function App() {

  return (
    <ApolloProvider client={client}>
      <Router>
        <Switch>
          <Route path="/events/:organizationName" component={Organization} />
          <Route path="/organizations">
            <AllOgranizations/>
          </Route>
          <Route path="/login">
          </Route>
          <Route path="/events">
            <Events />
          </Route>
          <Route path="/Signup">
            <Enrollment/>
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Router>
    </ApolloProvider>
  );
}

export default App;
